<template>
  <div>
    <section
      id="intro"
      class="main-row"
    >
      <b-container class="px-md-0">
        <b-row>
          <b-col
            md="7"
            lg="6"
          >
            <h1 v-html="$t('hos.intro.title')"></h1>
            <h2 v-html="$t('hos.intro.subtitle')"></h2>
            <b-row
              class="buttons"
              tag="ul"
            >
              <b-col
                md="6"
                tag="li"
                class="pl-0 pb-2 pb-md-3"
              >
                <b-dd
                  ref="ddmenu"
                  block
                  :html="$t('hos.intro.btn-create-a-pad')"
                  menu-class="mt-1 rounded-0 pb-0"
                  placement="bottom"
                  size="lg"
                  variant="primary h-100"
                  offset="-2"
                >
                  <b-dd-form
                    class="px-0"
                    @submit="create"
                  >
                    <div class="px-3">
                      <b-form-group
                        label-for="pad-name"
                        :label="$t('hos.intro.name')"
                      >
                        <b-input-group
                          size="sm"
                        >
                          <b-form-input
                            id="pad-name"
                            v-model="name"
                            type="text"
                            maxlength="50"
                          />
                        </b-input-group>
                      </b-form-group>
                      <b-form-group
                        :label="$t('hos.intro.expiration')"
                        label-for="expiration"
                      >
                        <b-form-select
                          id="expiration"
                          v-model="selected"
                          size="sm"
                        >
                          <option
                            v-for="instance in Object.keys(instances)"
                            :key="instance"
                            :value="instance"
                            v-html="$t(instances[instance])"
                          ></option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="fb-g3 pt-2 pb-3 px-3">
                      <span
                        v-show="copy"
                        aria-hidden="true"
                        class="float-right mt-1 small text-success"
                        v-html="$t('hos.intro.copied')"
                      ></span>
                      <b-form-group
                        label-for="share-link"
                        :label="$t('hos.intro.link')"
                      >
                        <b-input-group
                          size="sm"
                        >
                          <b-form-input
                            id="share-link"
                            ref="share-link"
                            onclick="select()"
                            readonly
                            type="text"
                            :value="`https://${selected}.framapad.org/p/${sname}${suffix}`"
                          />
                          <b-input-group-append>
                            <b-input-group-text>
                              <a
                                aria-hidden="true"
                                href="#"
                                :title="$t('hos.intro.copy')"
                                variant="fb-d0"
                                @click.prevent="copyLink"
                              >
                                <i class="fas fa-paste fc-g7"></i>
                              </a>
                            </b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <div class="text-right">
                        <b-button
                          variant="primary"
                          size="sm"
                          :href="`https://${selected}.framapad.org/p/${sname}${suffix}`"
                        >
                          <span v-html="$t('hos.intro.btn-create')"></span>
                        </b-button>
                      </div>
                    </div>
                  </b-dd-form>
                </b-dd>
              </b-col>
              <b-col
                md="6"
                tag="li"
                class="pl-0 pb-2 pb-md-3"
              >
                <b-button
                  block
                  href="https://mypads2.framapad.org/mypads/?/subscribe"
                  size="lg"
                  variant="outline-light h-100"
                  v-html="$t('hos.intro.btn-signup')"
                />
              </b-col>
              <b-col
                md="6"
                tag="li"
                class="pl-0 pb-2 pb-md-3"
              >
                <b-button
                  block
                  :href="$t('hos.data.docs')"
                  size="lg"
                  variant="outline-warning"
                  onclick="return false;"
                  @click="docs = true"
                >
                  <i class="fas fa-circle-play"></i>
                  <span v-html="$t('hos.intro.btn-tour')"></span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          id="learn-more"
          aria-hidden="true"
          align-h="center"
          class="d-none d-sm-flex"
        >
          <input
            id="btn-learn-more"
            type="checkbox"
            class="sr-only"
            @change="$parent.$children[1].$refs['what']
              .scrollIntoView({ behavior: 'smooth' })"
          />
          <label
            for="btn-learn-more"
            class="btn btn-sm btn-link p-0"
          >
            <span v-html="$t('hos.intro.more', $t('hos.data'))"></span>
            <i class="fas fa-lg fa-chevron-down d-block fc-g8"></i>
          </label>
        </b-row>
      </b-container>
    </section>

    <modal-docs
      :link="$t('hos.data.docs')"
      :open="docs"
    />
  </div>
</template>

<script>
import ModalDocs from '../../../commons/components/ModalDocs.vue';

export default {
  components: {
    ModalDocs,
  },

  data() {
    /* Random alphanumeric name with 10 chars */
    const name = [...Array(10)].map(() => Math.random().toString(36)[3]).join('')
      .replace(/(.|$)/g, c => c[!Math.round(Math.random()) ? 'toString' : 'toLowerCase']());

    return {
      copy: false,
      docs: false,
      instances: {
        hebdo: 'hos.intro.week',
        mensuel: 'hos.intro.month',
        semestriel: 'hos.intro.six-month',
        annuel: 'hos.intro.year',
      },
      suffix: `-${Math.trunc(new Date().getTime() / 3600000).toString(36)}?lang=${this.$t('lang')}`,
      name,
      selected: 'mensuel',
      status: {},
    };
  },

  computed: {
    sname() {
      return this.$t(this.name, '-kL@').replace(/[.]/g, '');
    },
  },

  mounted() {
    const q = Object.fromEntries(new URLSearchParams(window.location.search).entries());

    if (q.duration && Object.keys(this.instances).includes(q.duration)) {
      this.selected = q.duration;
      this.$refs.ddmenu.show();
    }

    if (q.name) {
      this.name = this.$t(q.name, '-kL@').replace(/[.]/g, '');
      this.$refs.ddmenu.show();
    }

    if (q.submit !== undefined) {
      this.create();
    }
  },

  methods: {
    create(e) {
      if (e) {
        e.preventDefault();
      }
      window.location = `https://${this.selected}.framapad.org/p/${this.sname}${this.suffix}`;
    },

    copyLink() {
      const input = this.$refs['share-link'];
      input.select();
      document.execCommand('copy');
      this.copy = true;
      setTimeout(() => { this.copy = false; }, 2000);
    },
  },
};
</script>

<style lang="scss">
#intro {
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.2));
  padding: 1rem 0 3rem;
  position: relative;
  z-index: 1; /* keep dropdown-menu visible */

  @media (max-width: 480px) {
    max-height: 600px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    background: var(--f-f7);
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
      clip-path: polygon(0 0, 0 100%, 100% 93%, 100% 0);
    }
  }

  &::after {
    background: var(--f-g5);
    background-image: url('../../../public/img/intro.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);

    @media (max-width: 768px) {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
      clip-path: polygon(0 0, 100% 0, 100% 98%, 0 95%);
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  h1 {
    color: var(--f-d1);
    font-size: 5rem;
    font-weight: 300;
    margin: 4rem 0 3rem;

    @media (max-width: 992px) {
      font-size: 4rem;
      margin: 3rem 0 2rem;
    }

    @media (max-width: 768px) {
      font-size: 3rem;
      margin: 2rem 0 1.5rem;
    }
  }

  h2 {
    color: var(--f-d1);
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.25;
    margin: 0;

    @media (max-width: 992px) {
      max-width: 75%;
      font-size: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  #learn-more {
    label {
      color: var(--f-d1);
      background: none;
      text-decoration: none;

      i::before {
        color: var(--f-d1);
      }
    }

    span {
      padding: .25rem ;
      font-size: .875rem;
    }

    input:checked ~ label {
      visibility: hidden;
    }
  }

 .buttons {
    margin: 4rem 0 5.5rem;
    padding: 0;
    list-style: none;
    max-width: 90%;

    @media (max-width: 768px) {
      margin: 3rem 0 1.5rem;
    }

    @media (max-width: 1200px) {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      max-width: 45%;
    }

    @media (max-width: 576px) {
      max-width: 66%;
    }

    > li {
      display: flex;
      align-items: center;

      > div > .btn,
      > .btn {
        display:flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0.5rem 1rem;
        white-space: normal;

        @media (max-width: 992px) {
          font-size: 1rem;
        }

        @media (max-width: 576px) {
          font-size: .875rem;
        }
      }

      .btn {
        font-weight: bold;
      }
    }

    .dropdown {
      width: 100%;
      height: 100%;
    }

    .dropdown-menu {
      background-color: var(--f-g1);

      li {
        width: 420px;
        @media (max-width: 460px) {
          width: 320px;
        }
      }
    }
  }

  .btn-outline-warning {
    background: #fff;
    border-color: #fff;
    font-weight: bold;

    &:hover, &:focus, &:active {
      background: var(--f-o7);
      border-color: var(--f-o7);
      color: #fff;
    }

    i.fas {
      margin-right: 0.25rem;
    }
  }

  .alert-outline {
    background: #fbfcfddd;
    margin-bottom: 2.5rem;
    max-width: 90%;

    @media (max-width: 768px) {
      max-width: 50%;
    }

    @media (max-width: 576px) {
      font-size: .875rem;
      margin-top: 2.5rem;
      margin-bottom: 0;
      max-width: 100%;
    }
  }
}
</style>
