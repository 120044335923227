<template>
  <section
    id="what"
    ref="what"
  >
    <b-container class="px-md-0">
      <b-row>
        <b-col
          lg="7"
        >
          <web-screen :img="`${$t('/')}img/${$t('lang')}/screenshot.png`" />
        </b-col>
        <b-col
          class="px-3"
          lg="5"
        >
          <h2
            class="sr-only"
            v-html="$t('hos.what.title')"
          ></h2>
          <div
            class="mt-4"
            v-html="$t('hos.what.md', {
              link: `https://hebdo.framapad.org/p/bac-a-sable${suffix}`
            })"
          ></div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import WebScreen from '../../../commons/components/WebScreen.vue';

export default {
  components: {
    WebScreen,
  },

  data() {
    return {
      suffix: `-${Math.trunc(new Date().getTime() / 3600000).toString(36)}`,
    };
  },
};
</script>

<style lang="scss">
#what {
  padding: 4rem 0;

  li:nth-child(4) > strong {
    background: linear-gradient(to right,
      var(--f-rouge),
      var(--f-orange),
      var(--f-jaune),
      var(--f-vert),
      var(--f-bleu),
      var(--f-violet)
    );
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}
</style>
